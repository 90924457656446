<template>
  <div
    style="max-width: 100%;"
  >
    <div class="is-flex is-justify-content-flex-end">
      <TableOptionsDropdown 
        @export="exportAsCsv()"
      />
    </div>

    <b-table
      ref="dataTableElement"
      :data="field.properties.basic.data"
      :paginated="field.properties.basic.pagination > 0"
      :per-page="field.properties.basic.pagination"
      pagination-size="is-small"
      class="data-table"
      @click="$emit('update',$event)"
    >
      <template v-for="column in field.properties.basic.headers">
        <b-table-column
          :key="column.id"
          v-bind="column"
          width="100"
        >
          <template
            #header
          > 
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-center">
                <span class="column-heading">
                  {{ column.label }}
                </span>
                <b-icon 
                  size="is-small"
                  :icon="sortedColumn === column.field && dataTableElement ? (dataTableElement.isAsc ? 'arrow-down' : 'arrow-up') : ''"
                />
              </div>
              <ColumnFiltersDropdown
                v-if="field.properties.basic.tableIsFilterable || field.properties.basic.tableIsSortable"
                :filter-icon="dataTableElement && dataTableElement.filters[column.field] ? 'filter': 'filter-variant'"
                @sort="handleSorting(column, $event)"
                @filter="handleFiltering(column, $event)"
              />
            </div>
          </template>
          <template #default="slotProps">
            {{ slotProps.row[column.field] }}
          </template>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script >
import { ref, set } from '@vue/composition-api';
import xlsx from 'json-as-xlsx';
import { cloneDeep } from 'lodash';
import ColumnFiltersDropdown from './ColumnFiltersDropdown.vue';
import TableOptionsDropdown from './TableOptionsDropdown.vue';
import FormRenderer from '@/helpers/FormRenderer';
import { extractReferenceId } from '@/helpers/ExpressionParser';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const sortedColumn = ref('');
  const dataTableElement = ref(null);
  const exportAsCsv = () => {
    const field = cloneDeep(props.field);
    if (field.properties.basic.exportAllColumns) {
      // handle extracting values from all columns
      let tableReference = extractReferenceId(field.properties.basic.columns[0].value);
      tableReference = tableReference.split('-').slice(0, 5).join('-');
      // generate mapping for all columns of table
      const allColumns = Object.keys(props.values).filter(key => key.includes(tableReference)).map(key => ({
        label: key.replace(`${tableReference}-`, ''),
        value: `<p><span class="mention" data-mention-id="${key}"></span></p>`,
        visible: true
      }));
      field.properties.basic.columns = allColumns;
      // let Form Renderer populate table data for all columns
      FormRenderer.renderForm(field, props.values);
    }
    const columns = field.properties.basic.headers.map((header, index) => ({
      label: header.label,
      value: `column_${index}` // create unique column values (these will be used for referencing values from each table row by json-as-xlsx plugin)
    }));
    const columnsMap = columns.reduce((result, currentColumn) => {
      result[currentColumn.label] = currentColumn.value;
      return result;
    }, {});
    const tableMappedData = field.properties.basic.data.reduce((result, currentRow) => {
      const key = currentRow.ID || currentRow.id;
      // currentRow object have keys according to column labels, map them to column values instead
      let mappedRow = {};
      Object.entries(currentRow || {}).forEach(([rowKey, rowValue]) => {
        const columnValueKey = columnsMap[rowKey];
        if (columnValueKey) {
          mappedRow[columnValueKey] = rowValue;
        }
      });
      result[key] = mappedRow;
      return result;
    }, {});

    // exported data as per applied column filters (if any)
    const exportedData = dataTableElement.value.newData.map(row => tableMappedData[row.ID || row.id] || null).filter(row => row);
    let data = [{
      sheet: 'Data Table',
      columns,
      content: exportedData
    }];
    let settings = {
      fileName: 'Data-Spreadsheet',
      extraLength: 3,
      writeOptions: {}
    };
    xlsx(data, settings);
  };
  const handleFiltering = (column, payload) => {
    const {
      keyword
    } = payload;
    set(dataTableElement.value.filters, column.field, keyword);
  };
  const handleSorting = (column, payload) => {
    const {
      sortOrder
    } = payload;
    sortedColumn.value = column.field;
    // accessing internal properties of b-table via template ref
    dataTableElement.value.isAsc = sortOrder === 'asc';
    dataTableElement.value.doSortSingleColumn({
      ...column,
      sortable: true
    });
  };
  return {
    sortedColumn,
    dataTableElement,
    exportAsCsv,
    handleFiltering,
    handleSorting
  };
};
__sfc_main.components = Object.assign({
  TableOptionsDropdown,
  ColumnFiltersDropdown
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.data-table {
  .table-wrapper {
    overflow: auto;
    min-height: 16rem
  }
  .table {
    .column-heading {
      white-space: nowrap;
    }
    .th-wrap {
      >span {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
