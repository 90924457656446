<template>
  <b-dropdown
    ref="filtersDropdownElement"
    aria-role="list"
    class="column-filters"
  >
    <template #trigger>
      <b-icon
        :icon="filterIcon"
      />
    </template>
    <b-dropdown-item
      aria-role="listitem"
      @click="handleSorting('asc')"
    >
      Sort Ascending
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="listitem"
      @click="handleSorting('desc')"
    >
      Sort Descending
    </b-dropdown-item>
    <hr class="my-1">
    <b-dropdown-item
      aria-role="listitem"
      custom
    >
      Filter:
      <b-input
        v-model="filterKeyword"
        size="is-small"
        type="search"
        class="mt-1"
      />
    </b-dropdown-item>
    <hr class="my-2">
    <b-dropdown-item
      aria-role="listitem"
      custom
    >
      <div class="is-flex is-justify-content-flex-end">
        <b-button 
          size="is-small"
          type="is-primary"
          @click="handleFiltering"
        >
          Apply
        </b-button>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script >
import { ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  filterIcon: {
    type: String,
    default: 'filter-variant'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const filterKeyword = ref('');
  const filtersDropdownElement = ref(null);
  const handleSorting = sortOrder => {
    emit('sort', {
      sortOrder
    });
  };
  const handleFiltering = () => {
    emit('filter', {
      keyword: filterKeyword.value
    });
    filtersDropdownElement.value.isActive = false;
  };
  return {
    filterKeyword,
    filtersDropdownElement,
    handleSorting,
    handleFiltering
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.column-filters {
  .dropdown-menu {
    width: 300px;
    .input, .button {
      height: 2rem !important;
    }
  }
}
</style>
